/* IMport axios to make request to server */
import axios from 'axios';
import { Enviroment } from '../../../config/enviroments'
export class PetSelectionService {
  
  static async getPets(idOwner: string) {
    /* Initlize the Envoriment Class to get url */
    let enviroment = new Enviroment();
    /* get url from envoriment service */
    const BASE_URL = await enviroment.getUrl();
    /* Config of request */
    let config :any = { 
      headers: {
        'Content-Type': 'application/json',
      },
    }
    //bUild the api
    const url= `${BASE_URL}/owner/${idOwner}`;

    //fetch the api
    return  await axios.get<any>(url, config);
    
  }      
  
}
