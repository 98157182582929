import PetSelectionPage from '../pages/petsSelection/petSelection';
import PetViewPage from '../pages/petView/petView';
import AddPets from '../pages/createPet/createPet';
// Interfaces
import IRoute from '../interfaces/route';

const routes: IRoute[] = [
  {
		path: '/nuevo',
		name: 'New Pet',
		component: AddPets,
		exact: true
	},
	{
		path: '/pets/:ownerId',
		name: 'Pet Selection',
		component: PetSelectionPage,
		exact: true
	},
	{
		path: '/pets/:ownerId/:name',
		name: 'Pet View',
		component: PetViewPage,
		exact: true
	},
  {
		path: '/',
		name: 'Home',
		component: PetSelectionPage,
		exact: true
	},
]

export default routes;