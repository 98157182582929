import React, { useState } from "react";
import { RouteComponentProps, withRouter  } from "react-router-dom";
import { IonButton,
  IonCheckbox,
  IonCol,
  IonContent,
  IonGrid,
  IonInput,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonModal,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonToast 
} from "@ionic/react";
// Services
import { PetService } from "./services/createPets";
// Interfaces
import { IAddPet, IOwnerAndPet } from "../../interfaces/owner.interface";
import IPage from "../../interfaces/page";

var petAndOwnerTemp: IOwnerAndPet = {
  petName: "",
  breed: "",
  bornDate: "",
  type: "PERRO",
  ownerPersonalId: "",
  petId: "",
  ownerName: "",
  email: "",
  address: "",
  phone: "",
  infoAuth: false
}

const AddPets: React.FunctionComponent<IPage & RouteComponentProps<any>> = props => {
  const [showAddOwnerModal, setShowAddOwnerModal] = useState<boolean> (false);
  const [showAddPetModal, setShowAddPetModal] = useState<boolean> (false);
  const [showToast, setShowToast] = useState<boolean> (false);
  const [petAndOwner] = useState<IOwnerAndPet> (petAndOwnerTemp);
  const [termsCheck, setTermsCheck] = useState<boolean> (false)

  const submitOwnerAndPet = () => {
    petAndOwner.petId = `${petAndOwner.petName}${petAndOwner.ownerPersonalId}`;
    // Create Owner + Pet Case
		if (showAddOwnerModal === true) {
      PetService.createOwnerAndPet (petAndOwner)
        .then (res =>{
          console.log (res.data) 
          setShowAddOwnerModal (!showAddOwnerModal);
        }).catch (console.log);
		// Ceate Only Pet case
    } else if (showAddPetModal === true) {
      const pet: IAddPet = {
        bornDate: petAndOwner.bornDate,
        breed: petAndOwner.breed,
        name: petAndOwner.petName,
        ownerPersonalId: petAndOwner.ownerPersonalId,
        petId: petAndOwner.petId,
        type: petAndOwner.type
      };
      PetService.create (pet)
        .then (res => {
          console.log (res.data) 
          setShowAddPetModal (!showAddPetModal);
        }).catch (console.log);
    }
  }
  return (
    <IonPage>
      <IonContent>
        <IonButton onClick={() => setShowAddOwnerModal (true)}>
          Nuevo Dueño
        </IonButton>
        <IonButton onClick={() => setShowAddPetModal (true)}>
          Nueva Mascota
        </IonButton>

        <IonModal
          mode="ios"
          isOpen={showAddOwnerModal || showAddPetModal}
          swipeToClose={true}
          onDidDismiss={() => (showAddOwnerModal === true) ? setShowAddOwnerModal (false) : setShowAddPetModal (false)}
        >
          <IonPage>
            <IonContent>
              <IonGrid>
                <IonRow>
                  <IonCol>
                    <h2>{(showAddOwnerModal === true) ? "Crear a Dueño + Mascota" : "Crear Mascota" }</h2>
                  </IonCol>
                </IonRow>
              </IonGrid>
              {
                (showAddOwnerModal === true) 
                ?
                <>
                  <IonItemDivider>Nombre Dueño</IonItemDivider>
                  <IonItem>
                    <IonInput
                      value={""}
                      placeholder="Ej: Andres"
                      type="text"
                      onIonChange={e => petAndOwner.ownerName = e.detail.value!}
                    ></IonInput>
                  </IonItem>
                  <IonItemDivider>Documento</IonItemDivider>
                  <IonItem>
                    <IonInput
                      value={""}
                      placeholder="CC|TI|CE"
                      type="text"
                      onIonChange={e => petAndOwner.ownerPersonalId = e.detail.value!}
                    ></IonInput>
                  </IonItem>
                  <IonItemDivider>Direccion</IonItemDivider>
                  <IonItem>
                    <IonInput
                      value={""}
                      placeholder="Ej: Calle 44"
                      type="text"
                      onIonChange={e => petAndOwner.address = e.detail.value!}
                    ></IonInput>
                  </IonItem>
                  <IonItemDivider>Telefono</IonItemDivider>
                  <IonItem>
                    <IonInput
                      value={""}
                      placeholder="Celular"
                      type="number"
                      onIonChange={e => petAndOwner.phone = e.detail.value!}
                    ></IonInput>
                  </IonItem>
                  <IonItemDivider>Correo</IonItemDivider>
                  <IonItem>
                    <IonInput
                      value={""}
                      placeholder="adolf@correo.com"
                      type="email"
                      onIonChange={e => petAndOwner.email = e.detail.value!}
                    ></IonInput>
                  </IonItem>
                  <IonItemDivider>Politica de tratamiento de Datos</IonItemDivider>
                  <IonItem>
                    <IonLabel>Envio de Informacion: {termsCheck ? "Si" : "No"}</IonLabel>
                    <IonCheckbox checked={termsCheck} onIonChange={e => {petAndOwner.infoAuth=e.detail.checked;setTermsCheck(e.detail.checked)}} />
                  </IonItem>
                </>
                : 
                <>
                  <IonItemDivider>Documento del Dueño</IonItemDivider>
                  <IonItem>
                    <IonInput
                      value={""}
                      placeholder="Ej: 1143879488"
                      type="text"
                      onIonChange={e => petAndOwner.ownerPersonalId = e.detail.value!}
                    ></IonInput>
                  </IonItem>
                </>
              }
                <IonItemDivider>Nombre Mascota</IonItemDivider>
                <IonItem>
                  <IonInput
                    value={""}
                    placeholder="Adolf"
                    type="text"
                    onIonChange={e => petAndOwner.petName = e.detail.value!.trim()}
                  ></IonInput>
                </IonItem>
                <IonItem>
                <IonLabel>Tipo</IonLabel>
                <IonSelect value={petAndOwnerTemp.type} placeholder="Elige uno" onIonChange={e => petAndOwner.type = e.detail.value}>
                  <IonSelectOption value="GATO">Gatuno</IonSelectOption>
                  <IonSelectOption value="PERRO">Perruno</IonSelectOption>
                </IonSelect>
              </IonItem>
              <IonItemDivider>Cumpleaños Mascota</IonItemDivider>
              <IonItem>
                <IonInput
                  value={""}
                  type="date"
                  onIonChange={e => petAndOwner.bornDate = e.detail.value!}
                ></IonInput>
              </IonItem>
              <IonItemDivider>Raza Mascota</IonItemDivider>
              <IonItem>
                <IonInput
                  value={""}
                  type="text"
                  placeholder="Chihuahua"
                  onIonChange={e => petAndOwner.breed = e.detail.value!.trim()}
                ></IonInput>
              </IonItem>
            </IonContent>
              <IonButton onClick={() => submitOwnerAndPet()}>Agregar</IonButton>
          </IonPage>
        </IonModal>
        <IonToast
          color="tertiary"
          isOpen={showToast}
          onDidDismiss={() => setShowToast (false)}
          message="Se agrego correctamente"
          duration={400}
        />
      </IonContent>
    </IonPage>
  );
}

export default withRouter(AddPets);
