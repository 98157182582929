import axios from 'axios';
import { Enviroment } from '../../../config/enviroments'
import { IAddPet, IOwnerAndPet } from '../../../interfaces/owner.interface';

export class PetService{
  
  static async createOwnerAndPet (data: IOwnerAndPet) {
    /* Initlize the Envoriment Class to get url */
    let enviroment = new Enviroment ();
    /* get url from envoriment service */
    const BASE_URL = await enviroment.getUrl ();
    /* Config of request */
    let config = { 
      headers: {
        'Content-Type': 'application/json',
      },
    }
    //bUild the api
    const url= `${BASE_URL}/new/owner-and-pet`;
    //fetch the api
    return await axios.post<any> (url, data ,config);
  }

  static async create (data: IAddPet) {
    /* Initlize the Envoriment Class to get url */
    let enviroment = new Enviroment ();
    /* get url from envoriment service */
    const BASE_URL = await enviroment.getUrl ();
    /* Config of request */
    let config = { 
      headers: {
        'Content-Type': 'application/json',
      },
    }
    //bUild the api
    const url= `${BASE_URL}/new/pet`;
    //fetch the api
    return  await axios.post<any> (url, data ,config);
    
  }  
  
}