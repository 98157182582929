export enum PetTypeEnum {
  'GATO',
  'PERRO'
};

export const PetType = Object.freeze({
  cat: {
    value: 'GATO',
    description: 'Gato',
  },
  dog: {
    value: 'PERRO',
    description: 'Perro',
  },
});

export const MedicalRecordType = Object.freeze({
  vaccine: {
    value: 'VACUNA',
    description: 'Vacuna',
  },
  deworming: {
    value: 'DESPARACITACIÓN',
    description: 'Desparacitación',
  }
});
