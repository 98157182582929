// React dependences
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { 
	IonModal,
	IonButton,
	IonContent,
	IonPage,
	IonGrid,
	IonRow,
	IonCol,
	IonCard,
	IonCardHeader,
	IonCardSubtitle,
	IonCardTitle,
	IonCardContent,
	IonList,
	IonItemDivider,
	IonItem,
	IonInput,
	IonLabel,
	IonChip,
	IonText,
  IonToast,
  IonThumbnail,
} from '@ionic/react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
// Services
import { PetViewService } from './services/petView.service'
// Interfaces
import IPage from "../../interfaces/page";
import {IHealthRecord, IPet} from '../../interfaces/pet.interface';
import './petView.css';
// Capacitor plugins
import { Camera, CameraResultType } from '@capacitor/camera';
import { FileConverter } from '../../services/fileConverter';
import { MedicalRecordType } from "../../config/enums";
const QRCode = require('qrcode.react');

interface IPageProps {
	ownerId: string,
	pets: IPet[],
	petName: string
}

const healthRecordTemp: IHealthRecord = {
  _id: "",
	date: "",
	nextDueOn: "",
	petId: "",
	description: "",
	type : "DESPARACITACIÓN",
  drugImage : "none",
  deleted: false
}

const PetViewPage: React.FC<IPage & RouteComponentProps<{},any,IPageProps>> = props => {
  const { register, handleSubmit, formState: { errors }} = useForm<IHealthRecord>({
    defaultValues :healthRecordTemp
  });
	const [showVaccinationModal, setShowVaccinationModal] = useState<boolean> (false);
	const [showDewormingModal, setShowDewormingModal] = useState<boolean> (false);
  const [showToast, setShowToast] = useState<boolean> (false);
  const [showDeleteToast, setShowDeleteToast] = useState<boolean> (false);
	const [pet, setPet] = useState<IPet> ();
  // React Photo Crop properties
  const [crop, setCrop] = useState<any> ({ unit: '%', width: 30, aspect: 16 / 9 });
	const [upImg, setUpImg] = useState<any>();
	const imgRef = useRef(null);
	const [completedCrop, setCompletedCrop] = useState<any>(null);
  const [cropedImage, setCropedImage] = useState<any> (null);
	const previewCanvasRef = useRef(null);

	const getPetHealthInfo = useCallback (
		() => {
      // If the user enter by url to a specific pet view
      let petName = "";
      let ownerId = "";
      if (!props.location.state) {
        // choose the last section of the currentUrl
        let urlSplited = window.location.href.split ("/"); 
        petName = urlSplited[ window.location.href.split ("/").length - 1 ];
        ownerId = urlSplited[ window.location.href.split ("/").length - 2 ];
      } else {
        let pets = props.location.state.pets;
        let currentPet = pets.filter(pet => pet.name === props.location.state.petName )[0];
        petName = currentPet.name;
        ownerId = props.location.state.ownerId;
      }
      petName = petName.replace(/_/g, "%20");

      PetViewService.getHealthRecord (petName, ownerId)
        .then (res=>{
          console.log (res.data.data)
          setPet (res.data.data)
        })
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []
	);

	useEffect (() => {
		getPetHealthInfo ();
	}, [props, getPetHealthInfo])
	
  const onSubmit = async (data: IHealthRecord) => {
    delete data._id;
	  if (pet) {
      data.petId = pet.petId;
      if (showVaccinationModal === true) {
        data.type = "VACUNA";
        const ownerId = window.location.href.split ("/")[window.location.href.split ("/").length-2];
        await PetViewService.uploadVaccineImage (pet.petId, ownerId, cropedImage)
          .then (res => {
            data.drugImage = res.data.data.remoteUrl;
            return res;
          }).catch (console.log);
      }
      PetViewService.sendHealthRecord (data)
        .then (() => {
          setShowToast (true);
          if (showDewormingModal === true) {
            setShowDewormingModal (false);
          } else if (showVaccinationModal === true) {
            setShowVaccinationModal (false);
          }
          getPetHealthInfo ();
        })
        .catch (console.log);
    }
  }
  
	const onSelectFile = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

	const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

	useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;
		if (image && canvas) {// @ts-ignore
			const scaleX = image.naturalWidth / image.width;// @ts-ignore
			const scaleY = image.naturalHeight / image.height;// @ts-ignore
			const ctx = canvas.getContext('2d');
			const pixelRatio = window.devicePixelRatio;// @ts-ignore
			canvas.width = crop.width * pixelRatio;// @ts-ignore
			canvas.height = crop.height * pixelRatio;
	
			ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
			ctx.imageSmoothingQuality = 'high';
	
			ctx.drawImage(
				image,
				crop.x * scaleX,
				crop.y * scaleY,
				crop.width * scaleX,
				crop.height * scaleY,
				0,
				0,
				crop.width,
				crop.height
			);
			// @ts-ignore
			canvas.toBlob(blob => {
				if (blob) {
					console.log (blob)
					setCropedImage (blob);
				}
			}, 'image/png', 1);

		}
  }, [completedCrop]);
  
  const changeImagePet = async () => {
    let urlSplited = window.location.href.split ("/"); 
    const ownerId = urlSplited[window.location.href.split ("/").length-2];
    const image = await Camera.getPhoto({
      quality: 90,
      allowEditing: true,
      resultType: CameraResultType.Base64
    });
    // image.webPath will contain a path that can be set as an image src.
    // You can access the original file using image.path, which can be
    // passed to the Filesystem API to read the raw data of the image,
    // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
    if (image.base64String && pet) {
      const File = await FileConverter.convertFile(image.base64String,`image/${image.format}`,512)
        .then(data =>{
          console.log(data);
          return data;
        })
        .catch(err=>{
          alert ("Intenta con otro archivo");
        });
      if (File) {
        const remoteUrl = await PetViewService.uploadVaccineImage (pet.petId, ownerId, File)
          .then (res => {
            return res.data.data.remoteUrl;
          });
        
        if (remoteUrl) {
          PetViewService.addImgtoPet (remoteUrl, pet.petId)
            .then (res => {
              const updatedValue = {...pet}
              updatedValue.img = remoteUrl;
              setPet({
                ...pet,
                ...updatedValue
              });
            });
        }
      }
    }
  };

  const deleteMedicalRecord = (recordId: string) => {
    if (pet) {
      PetViewService.deleteMedicalRecord (recordId, pet.petId)
        .then (() => {
          setShowDeleteToast (true);
          getPetHealthInfo ();
        })
        .catch (console.log);
    };
  };

  
	return (
		<IonPage>
			<IonContent> 
        <IonGrid>
          <IonRow className="ion-justify-content-center">
            <IonCol className="ion-text-center" size="4" sizeLg="2">
              <img 
                src={(pet?.img) ? pet.img : "../../assets/images/mateo.png"}
                alt="Pet"
              />
            </IonCol>
          </IonRow>
          <IonRow className="ion-justify-content-center">
            <IonCol size="auto" className="ion-text-center">
                <IonButton mode="ios" onClick={changeImagePet}>Cambiar Imagen</IonButton>
              </IonCol>
            </IonRow>
          <IonRow className="ion-justify-content-center ion-align-items-center">
            <IonCol  size="8" sizeSm="4" sizeLg="3">
              <div className="div-inline inter-medium">Nombre:&nbsp;</div><div className="div-inline inter-regular">{pet?.name}</div>
              <br/>
              <div className="div-inline inter-medium">Edad:&nbsp;</div><div className="div-inline inter-regular">{pet?.bornDate}</div>
              <br/>
              <div className="div-inline inter-medium">Tipo:&nbsp;</div><div className="div-inline inter-regular">{pet?.type}</div>
              <br/>
              <div className="div-inline inter-medium">Raza:&nbsp;</div><div className="div-inline inter-regular">{pet?.breed}</div>
            </IonCol>
            <IonCol size="3" sizeSm="2" sizeLg="1" className="ion-text-right ion-no-margin">
              <img 
                src="../../assets/images/logopets.png"
                alt=""
                width="100"
                height="100"
              />
            </IonCol>
          </IonRow>

          <IonRow className="ion-justify-content-center">
            <IonCol size="auto">
              <QRCode
                size={200}
                imageSettings={{src:(pet?.img) ? pet.img : "../../assets/images/mateo.png",height:30,width:30}}
                className="ion-margin"
                value={window.location.href}
              />
            </IonCol>
          </IonRow>

          <IonRow className="ion-justify-content-center">
            <IonCol size="auto">
              <IonButton mode="ios" onClick={() => setShowVaccinationModal (true)}>Agregar Vacuna</IonButton>
            </IonCol>
            <IonCol size="auto">
              <IonButton mode="ios" color="tertiary" fill="outline" onClick={() => setShowDewormingModal (true)}>Agregar Desparasitación</IonButton>
            </IonCol>
          </IonRow>

          <IonRow className="ion-justify-content-center">
            <IonCol size="auto">
              <IonCard mode="ios">
                
                <IonCardHeader>
                  <IonCardSubtitle>Vida Peluda</IonCardSubtitle>
                  <IonCardTitle>Carné de Vacunacion de {pet?.name}</IonCardTitle>
                </IonCardHeader>

                <IonCardContent>
                  <IonList>
                    {
                      pet?.medicalHistory.map ((record, index) => {
                        if (!record.deleted) {
                          if (record.type === MedicalRecordType.vaccine.value) {
                            return (
                              <IonItem key={index}>
                                <IonThumbnail onClick={() => {window.location.href = record.drugImage}} slot="end">
                                  <img alt="" src={record.drugImage} />
                                </IonThumbnail>
                                <IonLabel className="ion-text-wrap">
                                  <IonChip color={(record.type === "VACUNA" ? "primary" : "tertiary")}>{record.type}</IonChip>
                                  <IonText color="medium">
                                    <h2>Aplicacion: {record.date}</h2>
                                  </IonText>
                                  <IonText color="medium">
                                    <h2>Proxima: {record.nextDueOn}</h2>
                                  </IonText>
                                  <IonText color="medium">
                                    <h2>Descripcion: {record.description}</h2>
                                  </IonText>
                                </IonLabel>
                                <IonButton color="danger" onClick={() => deleteMedicalRecord (record._id || "")}>
                                  Eliminar
                                </IonButton>
                              </IonItem>
                            );
                          }
                          // Deworming type
                          return (
                            <IonItem key={index}>
                              <IonLabel>
                                <IonChip color={(record.type === "VACUNA" ? "primary" : "tertiary")}>{record.type}</IonChip>
                                <IonText color="medium">
                                  <h2>Aplicación: {record.date}</h2>
                                </IonText>
                                <IonText color="medium">
                                  <h2>Próxima: {record.nextDueOn}</h2>
                                </IonText>
                                <IonText color="medium">
                                <h2>Descripción: {record.description}</h2>
                                </IonText>
                              </IonLabel>
                              <IonButton color="danger" onClick={() => deleteMedicalRecord (record._id || "")}>
                                Eliminar
                              </IonButton>
                            </IonItem>
                          )
                          
                        } 
                        return <></>
                      })
                    }
                  </IonList>
                </IonCardContent>
            </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>
        
        <IonModal 
          mode="ios"
          isOpen={showDewormingModal}
          swipeToClose={true}
          onDidDismiss={() => setShowDewormingModal(false)}
        >
          <IonContent>
            <form onSubmit={handleSubmit(onSubmit)}>
            <IonGrid>
              <IonRow>
                <IonCol>
                  Desparasitación
                </IonCol>
              </IonRow>
            </IonGrid>
            <IonList>
              <IonItemDivider>Fecha de Aplicación</IonItemDivider>
              <IonItem>
                <IonInput type="date" {...register('date', { required: true })} />
                {errors.date && <IonText color="danger">Obligatorio</IonText>}
              </IonItem>

              <IonItemDivider>Siguiente Aplicación</IonItemDivider>
              <IonItem>
                <IonInput type="date" {...register('nextDueOn', { required: true })} />
                {errors.nextDueOn && <IonText color="danger">Obligatorio</IonText>}  
              </IonItem>

              <IonItemDivider>Nombre desparasitante</IonItemDivider>
              <IonItem>
                <IonInput type="text" {...register('description', { required: true})} />
                {errors.description && <IonText color="danger">El nombre es necesario</IonText>}
              </IonItem>
            </IonList>
            <IonButton expand="block" type="submit"> Agregar</IonButton>
            </form>
          </IonContent>

        </IonModal>

        {/* Vaccination Form */}
        <IonModal
          mode="ios"
          isOpen={showVaccinationModal}
          swipeToClose={false}
          onDidDismiss={() => setShowVaccinationModal (false)}
        >
          <IonContent>
            <form onSubmit={handleSubmit(onSubmit)}>
              <IonGrid>
                <IonRow>
                  <IonCol>
                    Vacuna
                  </IonCol>
                </IonRow>
              </IonGrid>
              <IonItemDivider>Foto del Sello</IonItemDivider>
              <IonItem>
                <input type="file" accept="image/*" onChange={onSelectFile}></input>
              </IonItem>
							<IonItem>
              <ReactCrop
								src={upImg}
								onImageLoaded={onLoad}
								crop={crop}
								onChange={(c) => setCrop(c)}
								onComplete={(c) => setCompletedCrop(c)}
							/>
							</IonItem>
							<IonItem>
								<canvas
									ref={previewCanvasRef}
									// Rounding is important so the canvas width and height matches/is a multiple for sharpness.
									style={{
										width: Math.round(completedCrop?.width ?? 0),
										height: Math.round(completedCrop?.height ?? 0)
									}}
								/>
							</IonItem>
              <IonItemDivider>Fecha de Aplicación</IonItemDivider>
              <IonItem>
                <IonInput type="date" {...register('date', { required: true })} />
                {errors.date && <IonText color="danger">Obligatorio</IonText>}
              </IonItem>

              <IonItemDivider>Siguiente Aplicación</IonItemDivider>
              <IonItem>
                <IonInput type="date" {...register('nextDueOn', { required: true })} />
                {errors.nextDueOn && <IonText color="danger">Obligatorio</IonText>}  
              </IonItem>

              <IonItemDivider>Nombre de Vacuna</IonItemDivider>
              <IonItem>
                <IonInput type="text" {...register('description', { required: true})} />
                {errors.description && <IonText color="danger">El nombre es necesario</IonText>}
              </IonItem>

              <IonButton 
								expand="block"
								type="submit"
								disabled={!completedCrop?.width || !completedCrop?.height}
							> Agregar</IonButton>
            </form>
          </IonContent>
        </IonModal>

        <IonToast
          color="tertiary"
          isOpen={showToast}
          onDidDismiss={() => setShowToast (false)}
          message="Se agrego correctamente"
          duration={400}
        />
        <IonToast
          color="danger"
          isOpen={showDeleteToast}
          onDidDismiss={() => setShowDeleteToast (false)}
          message="Se elimino el registro"
          duration={400}
        />
			</IonContent>
		</IonPage>
	);
}
export default withRouter(PetViewPage);