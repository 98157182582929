export class Enviroment {
  
  async getUrl () {
    /*read de node Env variable https://create-react-app.dev/docs/adding-custom-environment-variables/#expanding-environment-variables-in-env */
    if (process.env.NODE_ENV === 'production') {
      //Production mode
      return 'https://api.slinqer.com/pets';

    } else {
      // return 'http://localhost:3000/pets'
      return 'https://api.slinqer.com/pets';
    }
  };

  static getApiUrl = () => 'https://api.slinqer.com/pets';

}

