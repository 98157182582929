
/* IMport axios to make request to server */
import axios, { AxiosRequestConfig } from 'axios';
import { Enviroment } from '../../../config/enviroments';
import { IHealthRecord, IPet } from '../../../interfaces/pet.interface';

const URL_API = process.env.REACT_APP_URL_API || "";
export class PetViewService {

  static async getHealthRecord (petName: string, ownerId: string) {
    ///* Initlize the Envoriment Class to get url */
    let enviroment = new Enviroment ();
    /* get url from envoriment service */
    const BASE_URL = await enviroment.getUrl ();
    /* Config of request */
    const config = { 
     headers: {
       'Content-Type': 'application/json',
     },
    }
    // Build the string
    let url = `${BASE_URL}/${ownerId}/${petName}`;
    //fetch the api
    interface IResponse {
      data : IPet,
      code : string,
      success : string      
    }
    return await axios.get<IResponse> (url, config);
  }

  static async uploadVaccineImage (petId: string, ownerId: string, file: any) {
    ///* Initlize the Envoriment Class to get url */
    let enviroment = new Enviroment ();
    /* get url from envoriment service */
    const BASE_URL = await enviroment.getUrl ();
    const config = { 
      headers: {
        'Content-Type': 'multipart/form-data'
      },
    }
    const URL = `${BASE_URL}/upload-drug-image?petId=${petId}&ownerPersonalId=${ownerId}`
    let data = new FormData();
    data.append ("file", file, file.name);
    
    interface IResponse {
      data : {remoteUrl:string},
      code : string,
      success : string      
    }
    return await axios.post<IResponse> (URL, data, config);
  }

  static async sendHealthRecord (data: IHealthRecord) {
    ///* Initlize the Envoriment Class to get url */
    let enviroment = new Enviroment ();
    /* get url from envoriment service */
    const BASE_URL = await enviroment.getUrl ();
    let url = `${BASE_URL}/medical-record`;
    interface IResponse {
      data: any,
      code: string,
      success: string      
    }
    return await axios.post<IResponse> (url, data);

  }

  static async addImgtoPet (img: string, petId: string) {
    ///* Initlize the Envoriment Class to get url */
    let enviroment = new Enviroment ();
    /* get url from envoriment service */
    const BASE_URL = await enviroment.getUrl ();
    let url = `${BASE_URL}/new/pet-image`;
    const data = {
      img,
      petId
    }
    interface IResponse {
      data: any,
      code: string,
      success: string      
    }
    return await axios.put<IResponse> (url, data);
  }

  static async deleteMedicalRecord (id: string, petId: string) {
    const URL = `${URL_API}/medical-record`;

    const payload: AxiosRequestConfig = {
      data: {
        id,
        petId
      }
    }
    interface IResponse {
      data: any,
    }
    return axios.delete<IResponse> (URL, payload);
  }

}
