import React, { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import './petSelection.css';
import { 
	IonContent,
	IonPage,
	IonGrid,
	IonRow,
	IonCol,
} from '@ionic/react';
// Services
import { PetSelectionService } from './services/petSelection.service'
// Interfaces
import IPage from "../../interfaces/page";
import { IPet } from '../../interfaces/pet.interface';

const PetSelectionPage: React.FunctionComponent<IPage & RouteComponentProps<any>> = props => {
	const [ownerId, setOwnerId] = useState<string> (props.match.params.ownerId)
	const [pets, setPets] = useState<IPet[]> ([]);

	const getPetsByOwner = useCallback (
		(id:string) => {
			PetSelectionService.getPets (id)
				.then (res =>{
					if (res.data) {
						setPets (res.data.data.pets);
					}
				}).catch (err => {
					// TODO: Refactor with a styled modal
					let ownerId = prompt ("Ups, aun no tenemos ningun peludo tuyo") ;
					if (ownerId) {
						setOwnerId (ownerId);
						getPetsByOwner (ownerId);
					} 
				})		
		},
		[]
	);

	useEffect (() => {
		// TODO REFACTOR THIS WITH A MORE STYLED MODAL
		let id = props.match.params.ownerId;
		if (!id) {
			id = prompt ('Cedula del Dueño');
			if (id) setOwnerId (id);
		}
		getPetsByOwner (id);
	}, [props,getPetsByOwner])
	
	const redirectToPetView = ( petName: string ) =>{
		// Replace blank espaces for underscore for user experiencie porpuses
		const petNameSend = petName.replace (/ /g,"_");
		// Redirect pet view
		props.history.push (`/pets/${ownerId}/${petNameSend}`,{ownerId, pets, petName});
	}

	return (
		<IonPage className="App">
			<IonContent>
				<IonGrid>

					<IonRow>
						<IonCol>
							<img 
									id="logoPets"
									src="../assets/images/logopets.png"
									alt=""
									width="200"
									height="250"
							/>
						</IonCol>
					</IonRow>

					<IonRow>
						<IonCol>
							<p className="inter-black ion-no-margin">
									Mis Peludos
							</p>
							<p className="inter-regular ion-no-margin">
									Seleccion uno para ver el carnet de Vacunacion
							</p>
						</IonCol>
					</IonRow>

					<IonRow id="dogs" className="ion-justify-content-center">
						{
							pets?.map((pet,index) =>{
								return (
									<IonCol key={index} size="12" sizeSm="3" className="ion-justify-content-center">
										<img 
											src={(pet.img) ? pet.img : "../assets/images/mateo.png"}
											alt="Pet"
											width="200"
											height="200"
											onClick={() => redirectToPetView(pet.name)}
										/>
										<br/>
										<p>{pet.name}</p>
									</IonCol>
								)
							})
						}
					</IonRow>
				</IonGrid>
			</IonContent>
		</IonPage>
	);
}
export default withRouter(PetSelectionPage);
